import React, { memo, useState, useContext, Fragment } from 'react';

import PropTypes from 'prop-types';
import { Panel, Grid, GridItem, Label, Flex, Button, Text } from '@sdflc/ui';

import {
  MenuHeader,
  DefaultLayout,
  PageLoading,
  CartItem,
  CartTotal,
  Header,
  ThankyouButtons,
  ThankyouMessage,
  PanelWithTitle,
} from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';
import { colors } from '../config';
import { getUsePos } from '../utils';
import { navigate } from 'gatsby';

const ThankYou = memo((props) => {
  const { firstTimeLoading, locationDetails } = useContext(SquareUpContext);
  const [usePos] = useState(getUsePos());
  const paymentInfo = props.location?.state ?? {};
  const reference_id = paymentInfo.reference_id;
  const notes = paymentInfo.fulfillments?.pickup_details?.note;
  const pickupTime = paymentInfo.fulfillments?.pickup_details?.pickup_at;
  const pickupName = paymentInfo.fulfillments?.pickup_details?.recipient?.display_name;
  const email = paymentInfo.fulfillments?.pickup_details?.recipient?.email_address;
  const phoneNumber = paymentInfo.fulfillments?.pickup_details?.recipient?.phone_number;

  // console.log(paymentInfo);
  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <Panel backgroundColor={colors.lightGray3}>
        <DefaultLayout title='Thank You' hideCart>
          <MenuHeader hideTime />
          {!usePos ? (
            <ThankyouButtons />
          ) : (
            <Grid justifyContent='center'>
              <GridItem display='inline'>
                <Button
                  name='New Order'
                  variant='success'
                  onClick={() => navigate('/menu')}
                  icon='AddCircle'
                  dimension='large'
                  margin='5px'
                />
              </GridItem>
            </Grid>
          )}

          <Grid justifyContent='space-around'>
            <ThankyouMessage />
            <GridItem width={['100%', '100%', '70%', '70%']}>
              <PanelWithTitle marginTop='0px' padding='0px' border='unset' backgroundColor='unset'>
                <Text textAlign='center'>
                  <b>JETANA</b>
                </Text>

                <h3>
                  <Label>Pickup Details</Label>
                </h3>

                <Flex flexWrap='wrap'>
                  {reference_id && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>Order #</Label>
                      {reference_id}
                    </Flex>
                  )}

                  {pickupTime && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>Pickup Time</Label>
                      {new Date(pickupTime).toLocaleString()}
                    </Flex>
                  )}

                  {locationDetails.address && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>Pickup Address</Label>
                      {locationDetails.address}
                    </Flex>
                  )}

                  {pickupName && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>Pickup Name</Label>
                      {pickupName}
                    </Flex>
                  )}

                  {phoneNumber && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>Phone Number</Label>
                      {phoneNumber}
                    </Flex>
                  )}

                  {email && (
                    <Flex flexDirection='column' width={['100%', '33%']}>
                      <Label>E-Mail</Label>
                      {email}
                    </Flex>
                  )}

                  {notes && (
                    <Flex flexDirection='column' width='100%'>
                      <Label>Additional Notes</Label>
                      {notes}
                    </Flex>
                  )}
                </Flex>

                <h3>
                  <Label>Order Details</Label>
                </h3>

                <Grid>
                  <CartItem paymentInfo={paymentInfo} />
                </Grid>

                <CartTotal paymentInfo={paymentInfo} />
              </PanelWithTitle>
            </GridItem>
          </Grid>
        </DefaultLayout>
      </Panel>
    </Fragment>
  );
});

ThankYou.displayName = 'ThankYou';

ThankYou.propTypes = {};

ThankYou.defaultProps = {};

export default withProviders(ThankYou);
